import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  error: null,
}

export const getPrizesRequests = createAsyncThunk(
  "contracts/getPrizesRequests",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/prizes_requests?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updatePrizeRequest = createAsyncThunk(
  "contracts/updatePrizeRequest",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/prizes_requests/${args._id}`,
        args.values,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPrizesRequests.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPrizesRequests.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
        state.error = null;
      })
      .addCase(getPrizesRequests.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(updatePrizeRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePrizeRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.error = null;
      })
      .addCase(updatePrizeRequest.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default contractsSlice.reducer;