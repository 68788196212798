import { useState, useEffect, Suspense } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Flex, Spinner, useToast } from "@chakra-ui/react";

import Sidebar from "../layout/sidebar/Sidebar";
import Navbar from "../layout/navbar/Navbar";
import Footer from "../layout/footer/Footer";
import PageLoading from "../shared/loading/pageLoading/PageLoading";

import theme from "../global/theme";

import { verifyAuth } from "../../store/auth/authSlice";

import { AppWrapper } from "../../AppStyle";


const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebar = useSelector(state => state.sidebar);
  const auth = useSelector(state => state.auth);
  const [loaded, setLoaded] = useState(false);
  const toast = useToast({ position: "top", status: "success", duration: 3000 });

  useEffect(() => {
    if (auth.isAuth) {
      setLoaded(true);
    } else {
      dispatch(verifyAuth()).unwrap().then(_ => {
        setLoaded(true);
      }).catch(_ => {
        toast({ description: "انتهت صلاحية الدخول، قم بتسجيل الدخول مجددا" });
        navigate("/login");
      })
    }
  }, [dispatch, navigate, auth.isAuth]);

  return loaded ? (
    <AppWrapper>
      <Sidebar />
      <Box className={`content-wrapper ${sidebar.isNotOpened ? "active" : ""}`}>
        <Box className="content">
          <Navbar />
          <Box className="app-content">
            <Suspense fallback={(
              <Flex alignItems="center" justifyContent="center" h="80vh" bg={theme.light} border="1px solid #eee">
                <Spinner size="md" me={2} />
                <Box>جار تحميل الصفحة</Box>
              </Flex>
            )}>
              <Outlet />
            </Suspense>
          </Box>
        </Box>
        <Footer />
      </Box>
    </AppWrapper>
  )
    :
    <PageLoading />
};

export default ProtectedRoutes;
