import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  employeeDetails: null,
  itemsCount: 0,
  pages: 0,
  error: null
}

export const getEmployees = createAsyncThunk(
  "employees/getEmployees",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/employees?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createEmployee = createAsyncThunk(
  "employees/createEmployee",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/employees`,
        args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employees/updateEmployee",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/employees/${args._id}`,
        args.values,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employees/deleteEmployee",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/employees/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const employeesSlice = createSlice({
  name: "employees",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
        state.error = null;
      })
      .addCase(getEmployees.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message
      })

      .addCase(createEmployee.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createEmployee.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload.data);
        state.itemsCount++;
        state.error = null;
      })
      .addCase(createEmployee.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(updateEmployee.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateEmployee.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.error = null;
      })
      .addCase(updateEmployee.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(deleteEmployee.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteEmployee.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.error = null;
      })
      .addCase(deleteEmployee.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default employeesSlice.reducer;