import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  error: null,
}

export const pushNotifications = createAsyncThunk(
  "notifications/pushNotifications",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/notifications/push`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            "Accept-Language": document.documentElement.lang,
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/notifications?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "Accept-Language": document.documentElement.lang,
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateNotification = createAsyncThunk(
  "notifications/updateNotification",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/notifications/${args._id}`,
        { isRead: true },
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(pushNotifications.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(pushNotifications.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(pushNotifications.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data?.data;
        state.itemsCount = payload.data?.itemsCount;
        state.pages = payload.data?.pages;
      })
      .addCase(getNotifications.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(updateNotification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateNotification.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.error = null;
      })
      .addCase(updateNotification.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default notificationsSlice.reducer;