import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as FiIcons from 'react-icons/fi';

import {
  Box, Flex, Text, Image,
  Accordion, AccordionItem, AccordionButton, AccordionPanel
} from '@chakra-ui/react';

import PushNotificationsModal from "./actions/PushNotificationsModal";
import { toggleSidebar } from '../../../store/sidebar/sidebarSlice';

import systemBg from '../../../assets/images/logo.png';

import { SidebarOverlay, SidebarWrapper } from './SidebarStyle';
import theme from "../../global/theme";


const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sidebar = useSelector(state => state.sidebar);

  const [showPushNotificationsModal, setShowPushNotificationsModal] = useState(false);

  return (
    <>
      {sidebar.isNotOpened && (
        <SidebarOverlay as={Box}
          className="sidebar-overlay" position="fixed" top="0" right="0" w="100%" h="100vh" bg="rgba(0, 0, 0, .5)" zIndex="99"
          role="button" onClick={() => dispatch(toggleSidebar())} />
      )}

      <SidebarWrapper className={sidebar.isNotOpened ? 'active' : ''}>
        <Flex alignItems="center">
          <Image w="60px" src={systemBg} />
        </Flex>
        <Text
          textTransform="capitalize" marginBlock={4} className="text"
          fontSize={16} color="#5e76a1" fontWeight="600"
        >
          {t('layout.sidebar.main')}
        </Text>

        <Box className="sidebar-links">
          <Accordion allowToggle={true} border="none">
            <AccordionItem border="none">
              <NavLink to="/" end className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiHome /></span>
                  <span className="text">{t('layout.sidebar.dashboard')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>

            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiSettings /></span>
                    <span className="text">{t('layout.sidebar.settings')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/settings/info">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.info')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/contracts">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.contracts')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/config">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.config')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/commission">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.commission')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/faq">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.faq')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/points">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.points')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/balance">
                      <FiIcons.FiChevronRight />
                      {/* <span>{t('layout.sidebar.balance')}</span> */}
                      <span>Balance</span>
                    </NavLink>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiFilePlus /></span>
                    <span className="text">{t('layout.sidebar.documents')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/settings/terms_conditions">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.terms_conditions')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/privacy_policy">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.privacy_policy')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings/refund_policy">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.refund_policy')}</span>
                    </NavLink>
                  </li>

                </ul>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiUsers /></span>
                    <span className="text">{t('layout.sidebar.users')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/employees">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.employees')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/users">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.users')}</span>
                    </NavLink>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>

            {/* app actions */}
            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiActivity /></span>
                    <span className="text">{t('layout.sidebar.app_actions')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/free_services">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.free_services')}</span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/prizes">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.prizes')}</span>
                    </NavLink>
                  </li> */}

                  <li>
                    <button
                      type="button"
                      onClick={() => setShowPushNotificationsModal(true)}
                    >
                      <Flex alignItems="center" color="white">
                        <FiIcons.FiChevronRight color={theme.secondary} />
                        <span>{t('layout.sidebar.push_notifications')}</span>
                      </Flex>
                    </button>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>

            {/* requests */}
            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiRepeat /></span>
                    <span className="text">{t('layout.sidebar.requests')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/properties_requests">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.properties_requests')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/videos">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.videos')}</span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/free_services/requests">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.free_services_requests')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/prizes/requests">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.prizes_requests')}</span>
                    </NavLink>
                  </li> */}

                  <li>
                    <NavLink to="/disclaimer_requests">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.disclaimer_requests')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/otps">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.otp_requests')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/end_contract">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.end_contract')}</span>
                    </NavLink>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>

            {/* rents */}
            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiDollarSign /></span>
                    <span className="text">{t('layout.sidebar.rents')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/invoices">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.invoices')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/diarco_commission">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.diarco_commission')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/bookey_fees">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.bookey_fees')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/insurance">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.insurance')}</span>
                    </NavLink>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>

            {/* installments */}
            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiDollarSign /></span>
                    <span className="text">{t('layout.sidebar.installments')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/installment_invoices">
                      <FiIcons.FiChevronRight />
                      <span>invoices</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/installments_diarco_commission">
                      <FiIcons.FiChevronRight />
                      <span>dyarko commission</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/installments_bookey_fees">
                      <FiIcons.FiChevronRight />
                      <span>bookey fees</span>
                    </NavLink>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none">
              <NavLink to="/transfers" end className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiDollarSign /></span>
                  <span className="text">{t('layout.sidebar.transfer_balance')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
          </Accordion>
        </Box>

        <Text
          textTransform="capitalize" marginBlock={4} className="text"
          fontSize={16} color="#5e76a1" fontWeight="600"
        >
          {t('layout.sidebar.admin_consumer')}
        </Text>
        <Box className="sidebar-links">
          <Accordion allowToggle={true} border="none">
            <AccordionItem border="none">
              <NavLink to="/consumer/consumers" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiUsers /></span>
                  <span className="text">{t('layout.sidebar.consumers')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="/consumer/tours" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiServer /></span>
                  <span className="text">{t('layout.sidebar.tours')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="/consumer/rents" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiServer /></span>
                  <span className="text">{t('layout.sidebar.rents')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="/consumer/installments" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiBookmark /></span>
                  <span className="text">{t('layout.sidebar.installments')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="/consumer/invoices" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiFileText /></span>
                  <span className="text">{t('layout.sidebar.invoices')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="/consumer/points" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiDollarSign /></span>
                  <span className="text">{t('layout.sidebar.points')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="/consumer/contracts" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiFile /></span>
                  <span className="text">{t('layout.sidebar.contracts')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
          </Accordion>
        </Box>

        <Text
          textTransform="capitalize" marginBlock={4} className="text"
          fontSize={16} color="#5e76a1" fontWeight="600"
        >
          {t('layout.sidebar.admin_owner')}
        </Text>
        <Box className="sidebar-links">
          <Accordion allowToggle={true} border="none">
            <AccordionItem border="none">
              <NavLink to="owner/owners" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiUsers /></span>
                  <span className="text">{t('layout.sidebar.owners')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="owner/properties" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiChevronsUp /></span>
                  <span className="text">{t('layout.sidebar.properties')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="videos" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiBookOpen /></span>
                  <span className="text">{t('layout.sidebar.videos')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="owner/points" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiBookOpen /></span>
                  <span className="text">{t('layout.sidebar.points')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="owner/promoted" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiBookOpen /></span>
                  <span className="text">{t('layout.sidebar.promoted')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
          </Accordion>
        </Box>

        <Text
          textTransform="capitalize" marginBlock={4} className="text"
          fontSize={16} color="#5e76a1" fontWeight="600"
        >
          {t('layout.sidebar.erp')}
        </Text>
        <Text
          textTransform="capitalize" marginBlock={4} className="text"
          fontSize={12} color="#5e76a1" fontWeight="600"
        >
          {t('layout.sidebar.income')}
        </Text>
        <Box className="sidebar-links">
          <Accordion allowToggle={true} border="none">
            <AccordionItem border="none">
              <NavLink to="wallet" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiCompass /></span>
                  <span className="text">{t('layout.sidebar.wallet')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="erp/insurance" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiCompass /></span>
                  <span className="text">{t('layout.sidebar.insurance')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="installment_commission" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiCompass /></span>
                  <span className="text">{t('layout.sidebar.installment_commission')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="rent_commission" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiCompass /></span>
                  <span className="text">{t('layout.sidebar.rent_commission')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
          </Accordion>
        </Box>
        <Text
          textTransform="capitalize" marginBlock={4} className="text"
          fontSize={12} color="#5e76a1" fontWeight="600"
        >
          {t('layout.sidebar.expenses')}
        </Text>
        <Box className="sidebar-links">
          <Accordion allowToggle={true} border="none">
            <AccordionItem border="none">
              <NavLink to="general_expenses" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiDollarSign /></span>
                  <span className="text">{t('layout.sidebar.general_expenses')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="admin_expenses" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiDollarSign /></span>
                  <span className="text">{t('layout.sidebar.admin_expenses')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>
            <AccordionItem border="none">
              <NavLink to="tech_expenses" className="chakra-accordion__button">
                <Flex alignItems="center">
                  <span className="icon"><FiIcons.FiDollarSign /></span>
                  <span className="text">{t('layout.sidebar.tech_expenses')}</span>
                </Flex>
              </NavLink>
            </AccordionItem>

            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiDollarSign /></span>
                    <span className="text">{t('layout.sidebar.salaries')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/advance">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.advance')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/bounce">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.bounce')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/deduction">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.deduction')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/salaries">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.net_salaries')}</span>
                    </NavLink>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none">
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <span className="icon"><FiIcons.FiRefreshCw /></span>
                    <span className="text">{t('layout.sidebar.reports')}</span>
                  </Flex>
                  <span className="collapse-icon"><FiIcons.FiChevronRight /></span>
                </Flex>
              </AccordionButton>
              <AccordionPanel padding={0}>
                <ul>
                  <li>
                    <NavLink to="/reports/general_expenses">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.general_expenses')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/reports/promoted">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.promoted')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/reports/published">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.published')}</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/reports/salaries">
                      <FiIcons.FiChevronRight />
                      <span>{t('layout.sidebar.salaries')}</span>
                    </NavLink>
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </SidebarWrapper>

      {showPushNotificationsModal && <PushNotificationsModal onClose={() => setShowPushNotificationsModal(false)} />}
    </>
  )
}

export default Sidebar