import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  error: null,
}

export const getCommission = createAsyncThunk(
  "commission/getCommission",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/commissions?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createCommission = createAsyncThunk(
  "commission/createCommission",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/commissions`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateCommission = createAsyncThunk(
  "commission/updateCommission",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/commissions/${args._id}`,
        args.values,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteCommission = createAsyncThunk(
  "commission/deleteCommission",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/commissions/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
)

const commissionSlice = createSlice({
  name: "commission",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCommission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCommission.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.itemsCount = payload.data?.length;
        state.pages = 1;
        state.error = null;
      })
      .addCase(getCommission.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(createCommission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCommission.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.itemsCount++;
        state.data.push(payload.data);
        state.error = null;
      })
      .addCase(createCommission.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(updateCommission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCommission.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        // const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        // state.data[indexAt] = payload.data;
        state.error = null;
      })
      .addCase(updateCommission.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(deleteCommission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCommission.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.error = null;
      })
      .addCase(deleteCommission.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default commissionSlice.reducer;