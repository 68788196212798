import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUri, filterRequest } from "../../utilities/apiManager";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  error: null,
}

export const getInvoices = createAsyncThunk(
  "invoices/getInvoices",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/invoices?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const payInvoice = createAsyncThunk(
  "invoices/payInvoice",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/invoices/pay/${args.invoiceId}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(getInvoices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
      })
      .addCase(getInvoices.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(payInvoice.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(payInvoice.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(payInvoice.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default invoicesSlice.reducer;