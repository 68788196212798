import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUri, filterRequest } from "../../utilities/apiManager";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: [],
  property: null,
  itemsCount: 0,
  pages: 0,
  error: null,
}

export const getProperties = createAsyncThunk(
  "properties/getProperties",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/properties?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const getPropertyById = createAsyncThunk(
  "properties/getPropertyById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/properties/${args.propertyId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updatePropertyStatus = createAsyncThunk(
  "properties/updatePropertyStatus",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/properties/${args._id}/status`,
        args.values,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "properties/deleteProperty",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/properties/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProperties.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(getProperties.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data?.data;
        state.itemsCount = payload.data?.itemsCount;
        state.pages = payload.data?.pages;
      })
      .addCase(getProperties.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(getPropertyById.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(getPropertyById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.property = payload.data;
      })
      .addCase(getPropertyById.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(updatePropertyStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePropertyStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.error = null;
      })
      .addCase(updatePropertyStatus.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(deleteProperty.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteProperty.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.error = null;
      })
      .addCase(deleteProperty.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default propertiesSlice.reducer;