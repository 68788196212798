import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUri, filterRequest } from "../../utilities/apiManager";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  error: null,
}

export const getBounce = createAsyncThunk(
  "bounce/getBounce",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/bounce?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": document.documentElement.lang,
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createBounce = createAsyncThunk(
  "bounce/createBounce",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/bounce`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateBounce = createAsyncThunk(
  "bounce/updateBounce",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/bounce/${args._id}`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteBounce = createAsyncThunk(
  "bounce/deleteBounce",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/bounce/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const bounceSlice = createSlice({
  name: "bounce",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBounce.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(getBounce.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
      })
      .addCase(getBounce.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(createBounce.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createBounce.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload.data);
        state.itemsCount++;
        state.error = null;
      })
      .addCase(createBounce.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(updateBounce.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBounce.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.error = null;
      })
      .addCase(updateBounce.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(deleteBounce.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteBounce.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.error = null;
      })
      .addCase(deleteBounce.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default bounceSlice.reducer;