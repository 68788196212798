import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { apiUri } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  isAuth: false,
  user: null,
  accessToken: Cookies.get('accessToken') || null,
  error: null
}

export const login = createAsyncThunk(
  "auth/login",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/auth/login`,
        args,
        {
          headers: {
            "Accept-Language": document.documentElement.lang
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/auth/logout`,
        args,
        {
          headers: {
            "Accept-Language": document.documentElement.lang
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const verifyAuth = createAsyncThunk(
  "auth/verifyAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/users`,
        {
          headers: {
            "auth-token": `Bearer ${thunkApi.getState().auth.accessToken}`,
            "Accept-Language": document.documentElement.lang
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.isAuth = true;
        Cookies.set("accessToken", payload.data?.accessToken);
        Cookies.set("refreshToken", payload.data?.refreshToken);
        state.accessToken = payload.data?.accessToken;
        state.user = payload.data?.user;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
        state.isAuth = false;
        state.accessToken = null;
        state.user = null;
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
      })

      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.isAuth = false;
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        state.accessToken = null;
        state.user = null;
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(verifyAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyAuth.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.isAuth = true;
        state.user = payload.data;
      })
      .addCase(verifyAuth.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
        state.isAuth = false;
        state.accessToken = null;
        state.user = null;
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
      })
  }
});

export default authSlice.reducer