import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  rents: 0,
  tours: 0,
  installments: 0,
  savedProperties: 0,
  paidInvoices: 0,
  unpaidInvoices: 0,
  canceledInvoices: 0,
  points: 0,
  freeServices: 0,
  error: null
}

export const getUserStatics = createAsyncThunk(
  "statics/getUserStatics",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/statics/user/${args.userId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const ownerStaticsSlice = createSlice({
  name: "statics",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserStatics.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserStatics.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.rents = payload.rents;
        state.tours = payload.tours;
        state.installments = payload.installments;
        state.savedProperties = payload.savedProperties;
        state.paidInvoices = payload.paidInvoices;
        state.unpaidInvoices = payload.unpaidInvoices;
        state.canceledInvoices = payload.canceledInvoices;
        state.points = payload.points;
        state.freeServices = payload.freeServices;
        state.error = null;
      })
      .addCase(getUserStatics.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message
      })
  }
});

export default ownerStaticsSlice.reducer;