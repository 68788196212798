import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  videos: 0,
  properties: 0,
  activeProperties: 0,
  promotedProperties: 0,
  topViewProperties: [],
  topViewVideos: [],
  error: null
}

export const getOwnerStatics = createAsyncThunk(
  "statics/getOwnerStatics",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/statics/owner/${args.ownerId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const ownerStaticsSlice = createSlice({
  name: "statics",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOwnerStatics.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getOwnerStatics.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.videos = payload.videos;
        state.properties = payload.properties;
        state.activeProperties = payload.activeProperties;
        state.promotedProperties = payload.promotedProperties;
        state.topViewProperties = payload.topViewProperties;
        state.topViewVideos = payload.topViewVideos;
        state.topOwners = payload.topOwners;
        state.topPlans = payload.topPlans;
        state.error = null;
      })
      .addCase(getOwnerStatics.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message
      })
  }
});

export default ownerStaticsSlice.reducer;