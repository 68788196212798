import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Flex,
  Menu, MenuButton,
  MenuList, Text,
  Avatar, Box, Divider
} from '@chakra-ui/react';
import * as FiIcons from 'react-icons/fi';

import { toggleSidebar } from '../../../store/sidebar/sidebarSlice';
import { logout } from '../../../store/auth/authSlice';

import { NavbarWrapper } from './NavbarStyle';

import theme from '../../global/theme';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const sidebar = useSelector(state => state.sidebar);

  return (
    <NavbarWrapper className={`${sidebar.isNotOpened ? 'active' : ''}`}>
      <Flex alignItems="center" justifyContent="space-between">
        <Button
          p={0} bg="none"
          width="auto" color={theme.dark}
          _hover={{ bg: "none" }} _focus={{ outline: "none" }}
          marginInlineEnd={4} onClick={() => dispatch(toggleSidebar())}
        >
          <FiIcons.FiAlignRight size={25} />
        </Button>

        <Flex alignItems="center">
          <Button
            type="button" bg="none" color={theme.dark}
            _hover={{ bg: "none" }} _focus={{ outline: "none" }}
            onClick={() => {
              i18n.changeLanguage(i18n.language === "en" ? "ar" : "en")
            }}
          >
            <FiIcons.FiGlobe size={20} />
          </Button>

          <Button
            type="button" bg="none" color={theme.dark}
            _hover={{ bg: "none" }} _focus={{ outline: "none" }}
            onClick={() => navigate("/profile/notifications")}
          >
            <Box bg={theme.primary} width="10px" height="10px" borderRadius="50%" />
            <FiIcons.FiBell size={20} />
          </Button>
          <Menu>
            <MenuButton
              as={Button} bg="none" color={theme.dark}
              _hover={{ bg: "none" }} _focus={{ outline: "none" }}
            >
              <Flex alignItems="center">
                <Avatar name={auth.user?.name} size="sm" />
                <Text className="profile-text"
                  marginInline={3} textTransform="capitalize"
                  fontSize={14}
                >
                  {auth.user?.name && auth.user.name.slice(0, 10)}
                </Text>
                <FiIcons.FiChevronDown size={16} />
              </Flex>
            </MenuButton>
            <MenuList minW="180px">
              <Box p={4}>
                <Flex as={Link} to="/profile"
                  alignItems="center"
                  color={theme.dark} textTransform="capitalize"
                >
                  <FiIcons.FiUser />
                  <Text ms={2}>
                    {t('layout.navbar.profile')}
                  </Text>
                </Flex>
                <Divider marginBlock={2} />
                <Button
                  type="button"
                  onClick={() => {
                    dispatch(logout())
                      .then(() => navigate("/login"))
                  }}
                  disabled={auth.isLoading}
                  alignItems="center" bg="none" p={0} minH="auto" h="auto"
                  color={theme.dark} textTransform="capitalize" _hover={{ bg: "none" }}
                >
                  <FiIcons.FiLogOut />
                  <Text ms={2}>
                    {t('layout.navbar.logout')}
                  </Text>
                </Button>
              </Box>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </NavbarWrapper>
  )
}

export default Navbar