import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUri, filterRequest } from "../../utilities/apiManager";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  error: null,
}

export const getAdminExpenses = createAsyncThunk(
  "adminExpenses/getAdminExpenses",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/admin_expenses?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": document.documentElement.lang,
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createAdminExpense = createAsyncThunk(
  "adminExpenses/createAdminExpense",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/admin_expenses`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Content-Type": "multipart/form-data",
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateAdminExpense = createAsyncThunk(
  "adminExpenses/updateAdminExpense",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/admin_expenses/${args._id}`,
        args.values,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Content-Type": "multipart/form-data",
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteAdminExpense = createAsyncThunk(
  "adminExpenses/deleteAdminExpense",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/admin_expenses/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const adminExpensesSlice = createSlice({
  name: "adminExpenses",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAdminExpenses.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(getAdminExpenses.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
      })
      .addCase(getAdminExpenses.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(createAdminExpense.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createAdminExpense.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload.data);
        state.itemsCount++;
        state.error = null;
      })
      .addCase(createAdminExpense.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(updateAdminExpense.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateAdminExpense.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.error = null;
      })
      .addCase(updateAdminExpense.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })

      .addCase(deleteAdminExpense.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteAdminExpense.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.error = null;
      })
      .addCase(deleteAdminExpense.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload?.message;
      })
  }
});

export default adminExpensesSlice.reducer;